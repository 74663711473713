export const HEADERS = {
  FIO: 'Фамилия, Имя менеджера',
  COMPANY: 'Название компании',
  EMAIL: 'Email',
  PHONE: 'Телефон',
  // FIRST_NAME: 'Имя',
  // LAST_NAME: 'Фамилия',
  // PATRONYMIC: 'Отчество',
  TAXPAYER_ID_NUMBER: 'ИНН',
  // COMMENT: 'Комментарий',
};
//
// export const SCALES = {
//   0: 'Достоверность/Недостоверность',
//   1: 'Отрицание/Преувеличение проблем',
//   2: 'Откровенность/Неоткровенность',
//   3: 'Нейтральное самочувствие/Обеспокоенность здоровьем',
//   4: 'Реалистичность/Пессимистичность оценки',
//   5: 'Самодостаточность/Необходимость получения внимания',
//   6: 'Самоконтроль/Импульсивность',
//   7: 'Женские/Мужские черты в характере',
//   8: 'Гибкость/Упорство',
//   9: 'Уверенность/Нерешительность',
//   10: 'Нормативность/Индивидуализм',
//   11: 'Вялость/Энергичность',
//   12: 'Замкнутость/Общительность',
// };
//
// // export const TEAM_ROLES_1 = 'Реализатор';
// // export const TEAM_ROLES_2 = 'Координатор';
// // export const TEAM_ROLES_3 = 'Мотиватор';
// // export const TEAM_ROLES_4 = 'Генератор идей';
// // export const TEAM_ROLES_5 = 'Генератор ресурсов';
// // export const TEAM_ROLES_6 = 'Аналитик';
// // export const TEAM_ROLES_7 = 'Коллективист';
// // export const TEAM_ROLES_8 = 'Контролер';
//
// export const INTELLECTUAL_ABILITY = {
//   0: 'Вербальная логика',
//   1: 'Числовая логика',
//   2: 'Невербальная логика',
// };
// export const PROF_INTERESTS = {
//   0: 'Исследование информации и продвижение товаров/услуг',
//   1: 'Контроль и оценка финансов',
//   2: 'Образование',
//   3: 'Работа с документами и обслуживание процессов',
//   4: 'Продажа и переговоры',
//   5: 'Медицина',
//   6: 'Работа с техникой',
//   7: 'Создание событий, текстов или дизайна',
//   8: 'Управление процессами на линейном уровне',
//   9: 'Управление на уровне топ-менеджмента',
//   10: 'Проектирование и программирование',
//   11: 'Защита',
// };
