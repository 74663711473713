export default class ManagerTableModel {
  constructor(manager) {
    this.id = manager.id;
    this.firstName = manager.first_name;
    this.lastName = manager.last_name;
    this.fullName = `${manager.last_name} ${manager.first_name}`;
    this.company = manager.company;
    this.email = manager.email;
    this.phone = manager.phone;
    this.taxpayer_id_number = manager.taxpayer_id_number;
  }
}
